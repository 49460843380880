import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const TestimonialSection = styled.section`
  .testimonial-container {
    margin-bottom: 100px;
    background-color: var(--pink);
    position: relative;
    height: auto;
    display: flex;
    overflow: hidden;

    @media(min-width: 640px) {
      height: 254px;
    }

    .testimonial-left-overlay {
      position: absolute;
      top: 0;
      left: -70%;
      width: 100%;
      max-width: 100%;
      height: 100%;
  
      @media(min-width: 640px) {
        left: 0;
        max-width: 262px;
      }
    }
  
    .testimonial-right-overlay {
      position: absolute;
      top: 0;
      right: -70%;
      width: 100%;
      max-width: 100%;
      height: 100%;
  
      @media(min-width: 640px) {
        right: 0;
        max-width: 262px;
      }
    }
  
    .container {
      align-self: center;
      z-index: 1;
  
      .testimonial {
        max-width: 675px;
        width: 100%;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
        box-sizing: border-box;
  
        .testimonial-content {
          margin-bottom: 20px;
          font-size: 23px;
          line-height: 1.1;
          font-weight: var(--font-bold);
        }
  
        .testimonial-author {
          margin-bottom: 0;
          font-size: 21px;
          line-height: 1.1;
        }
      }
    }
  }

  .about-btn-container {
    text-align: center;
    margin-bottom: 100px;
  }
`

const AboutTestimonial = () => {

  const data = useStaticQuery(graphql`
    {
      aboutTestimonial: allContentfulAboutPage(
        filter: {pageTitle: {eq: "About FinTech Law"}}
      ) {
        edges {
          node {
            id
            aboutTestimonialContent {
              aboutTestimonialContent
            }
            aboutTestimonialAuthor
            contactButtonText
            contactButtonUrl
          }
        }
      }
    }
  `)

  return (
    <>
    {data.aboutTestimonial.edges.map(({ node }) => (
      <TestimonialSection key={node.id}>
        {node.aboutTestimonialContent &&
          <div className="testimonial-container">
            <StaticImage
              src="../images/testimonial-left-overlay.png"
              placeholder="blurred"
              objectFit="cover"
              alt=""
              className="testimonial-left-overlay"
            />
            <div className="container">
              <div className="testimonial">
                <p className="testimonial-content">{node.aboutTestimonialContent.aboutTestimonialContent}</p>
                <p className="testimonial-author">- {node.aboutTestimonialAuthor}</p>
              </div>
            </div>
            <StaticImage
              src="../images/testimonial-right-overlay.png"
              placeholder="blurred"
              objectFit="cover"
              alt=""
              className="testimonial-right-overlay"
            />
          </div>
        }
        <div className="about-btn-container">
          <a href={node.contactButtonUrl} className="btn">{node.contactButtonText}</a>
        </div>
      </TestimonialSection>
    ))}
    </>
  );
}

export default AboutTestimonial
