import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

const CareersSection = styled.section`
  width: 100%;
  max-width: 800px;
  margin: 0 auto 100px auto;
  h2 {
    text-align: center;
    margin-bottom: 25px;
  }
  .careers-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    column-gap: 45px;
    row-gap: 30px;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    @media(min-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }
    a {
      color: var(--pink);
    }
    .careers-left {
      .gatsby-image-wrapper {
        width: 250px;
        height: 250px;
      }
    }
  }
`

const AboutCareers = () => {

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri} target="_blank" rel="noreferrer">{children}</a>,
    },
  }

  const data = useStaticQuery(graphql`
    {
      aboutCareers: allContentfulAboutPage(
        filter: {pageTitle: {eq: "About FinTech Law"}}
      ) {
        edges {
          node {
            id
            hiringTitle
            hiringImage {
              gatsbyImageData(width: 500, placeholder: BLURRED)
              title
            }
            hiringContent {
              raw
            }
          }
        }
      }
    }
  `)

  return (
    <>
    {data.aboutCareers.edges.map(({ node }) => (
      <CareersSection key={node.id}>
        {node.hiringContent &&
          <>
            <h2>{node.hiringTitle}</h2>
            <div className="careers-container">
              <div className="careers-left">
                <GatsbyImage image={node.hiringImage.gatsbyImageData} alt={node.hiringImage.title} width={250} height={250} />
              </div>
              <div className="careers-right">
                {node.hiringContent && renderRichText(node.hiringContent, options)}
              </div>
            </div>
          </>
        }
      </CareersSection>
    ))}
    </>
  );
}

export default AboutCareers