import React from 'react'
import styled from "styled-components"
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import LinkedinIcon from "../images/icon-team-linkedin.svg"

const TeamSection = styled.section`
  margin-bottom: 120px;
  background-color: var(--black);

  .team-heading {
    text-align: center;
    margin-bottom: 50px;

    p {
      color: var(--gray);
    }
  }

  .team-card-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
  }

  .team-flip-card {
    background-color: var(--black);
    width: 280px;
    height: 280px;
    perspective: 1000px;

    @media(min-width: 480px) {
      width: 360px;
      height: 360px;
    }
  }

  .team-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .team-flip-card:hover .team-flip-card-inner {
    transform: rotateY(180deg);
  }

  .team-flip-card-front,
  .team-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .team-flip-card-front {
    backface-visibility: hidden;
    opacity: 0.99;
    position: relative;

    .bio-frame-back {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .bio-frame-front,
    .bio-frame-photo {
      position: absolute;
      top: 17px;
      left: 17px;
      z-index: 2;
    }

    .card-front-content {
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      p {
        color: var(--white);
        font-size: 22px;
        line-height: 1.1;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
  }

  .team-flip-card-back {
    opacity: 0.99;
    transform: rotateY(180deg);
    top: 0;

    .bio-frame-back {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .bio-frame-front,
    .card-back-content,
    .bio-frame-photo {
      position: absolute;
      top: 17px;
      left: 17px;
    }

    .bio-frame-photo {
      z-index: 3;
      max-width: 250px;

      @media(min-width: 480px) {
        max-width: 326px;
      }
    }

    .card-back-content {
      z-index: 6;
    }

    .bio-frame-front {
      z-index: 4;
    }

    .card-back-content {
      background-color: rgba(205 205 205 / 88%);
      position: absolute;
      top: 17px;
      left: 17px;
      width: 250px;
      height: 250px;
      padding: 20px;
      box-sizing: border-box;
      overflow-y: auto;

      @media(min-width: 480px) {
        width: 326px;
        height: 326px;
      }

      h4 {
        font-size: 22px;
        line-height: 1.1;
        margin-bottom: 10px;
        color: var(--black);
        font-family: var(--body-font);
        font-weight: var(--font-bold);
        text-transform: uppercase;
      }

      .team-position {
        font-size: 18px;
        line-height: 1;
        font-weight: var(--font-bold);
        color: var(--black);
        margin-bottom: 15px;
      }

      .team-bio {
        color: var(--black);
        font-size: 17px;
        line-height: 1.2;
        font-weight: var(--font-regular);
        margin-bottom: 15px;
      }

      .team-linkedin {

      }
    }
  }
`

const AboutTeam = () => {

  const data = useStaticQuery(graphql`
    {
      team: allContentfulAboutPage(
        filter: {pageTitle: {eq: "About FinTech Law"}}
      ) {
        edges {
          node {
            id
            teamSectionH2
            teamSectionParagraph {
              teamSectionParagraph
            }
          }
        }
      }
      teamMembers: allContentfulTeamOrder {
        edges {
          node {
            id
            teamOrder {
              id
              teamMemberFirstName
              teamMemberLastName
              teamMemberPosition
              teamMemberBio {
                teamMemberBio
              }
              teamLinkedin
              teamMemberPhoto {
                gatsbyImageData(width: 690, placeholder: BLURRED)
                title
              }
            }
          }
        }
      }
    }
  `)

  return (
    <TeamSection>
      {data.team.edges.map(({ node }) => (
        <div className="container" key={node.id}>
          <div className="team-heading">
            <h2>{node.teamSectionH2}</h2>
            <p>{node.teamSectionParagraph.teamSectionParagraph}</p>
          </div>
        </div>
      ))}
      {data.teamMembers.edges.map(({ node }) => (
        <div className="container" key={node.id}>
          <div className="team-card-grid">
            {node.teamOrder && node.teamOrder.map((item) => (
              <div key={item.id} className="team-flip-card">
                <div className="team-flip-card-inner">
                  <div className="team-flip-card-front">
                    <StaticImage
                      src="../images/home-photo-frame-back.png"
                      width={343}
                      height={343}
                      alt=""
                      className="bio-frame-back"
                      loading="eager"
                    />
                    <GatsbyImage image={item.teamMemberPhoto.gatsbyImageData} alt={item.teamMemberPhoto.title} width={343} height={343} className="bio-frame-photo" loading="eager" />
                    <StaticImage
                      src="../images/home-photo-frame-front.png"
                      width={343}
                      height={343}
                      alt=""
                      className="bio-frame-front"
                      loading="eager"
                    />
                  </div>
                  <div className="team-flip-card-back">
                    <StaticImage
                      src="../images/home-photo-frame-back.png"
                      width={343}
                      height={343}
                      alt=""
                      className="bio-frame-back"
                    />
                    <GatsbyImage image={item.teamMemberPhoto.gatsbyImageData} alt={item.teamMemberPhoto.title} width={326} height={326} className="bio-frame-photo" />
                    <StaticImage
                      src="../images/home-photo-frame-front.png"
                      width={343}
                      height={343}
                      alt=""
                      className="bio-frame-front"
                    />
                    <div className="card-back-content">
                      <h4>{item.teamMemberFirstName}<br />{item.teamMemberLastName}</h4>
                      <p className="team-position">{item.teamMemberPosition}</p>
                      <p className="team-bio">{item.teamMemberBio.teamMemberBio}</p>
                      {item.teamLinkedin &&
                        <a href={item.teamLinkedin} target="_blank" rel="noopener noreferrer">
                          <img src={LinkedinIcon} alt="Linkedin Icon" width="25" height="25" />
                        </a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </TeamSection>
  );
}

export default AboutTeam
