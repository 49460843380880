import React from 'react'
import styled from "styled-components"
import { graphql } from 'gatsby'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import AboutTeamSection from '../components/AboutTeam'
import AboutCareersSection from '../components/AboutCareers'
import AboutTestimonialSection from '../components/AboutTestimonial'
import NewsletterCTA from '../components/NewsletterCTA'

const OpeningParagraph = styled.section`
  margin-bottom: 50px;

  p {
    color: var(--gray);
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
`

const AboutPage = (props) => {
  return (
    <main>
      {props.data.pageTitleData.edges.map(({ node }) => (
        <PageTitle key={node.id} title={node.pageTitle} paragraph={node.pageTitleParagraph.pageTitleParagraph} />
      ))}
      {props.data.pageTitleData.edges.map(({ node }) => (
        <OpeningParagraph key={node.id}>
          <div className="container">
            <p>{node.openingParagraph.openingParagraph}</p>
          </div>
        </OpeningParagraph>
      ))}
      <AboutTeamSection />
      <AboutCareersSection />
      <AboutTestimonialSection />
      <NewsletterCTA />
    </main>
  )
}

export default AboutPage

export const Head = (seo) => {
  const seoData = seo.data.pageTitleData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const AboutContent = graphql`
  {
    pageTitleData: allContentfulAboutPage(
      filter: {pageTitle: {eq: "About FinTech Law"}}
    ) {
      edges {
        node {
          id
          pageTitle
          pageTitleParagraph {
            pageTitleParagraph
          }
          openingParagraph {
            openingParagraph
          }
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
  }
`;